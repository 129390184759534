import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Alert,
  Pagination,
} from '@mui/material';
import Sidebar from './Sidebar';
import { Logout } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const Upload = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 20; // Number of rows per page
  const [uploadedFile, setUploadedFile] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const [duplicateCIDs, setDuplicateCIDs] = useState([]);

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const closeSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: '' });
  };

  useEffect(() => {
    const fetchUploadedData = async () => {
      try {
        const token = localStorage.getItem('access_token');
        if (!token) {
          showSnackbar('Please log in first.', 'error');
          navigate('/login');
          return;
        }

        const response = await fetch('https://trainingportal.desuung.org.bt/api/get-uploaded-data', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch uploaded data: ${response.statusText}`);
        }

        const data = await response.json();

        if (Array.isArray(data)) {
          setRows(data);
        } else {
          console.warn('Unexpected response format:', data);
          setRows([]);
        }
      } catch (error) {
        console.error('Error fetching uploaded data:', error);
        showSnackbar(`Error fetching data: ${error.message}`, 'error');
        setRows([]);
      }
    };

    fetchUploadedData();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    sessionStorage.removeItem('access_token_citizen');
    navigate('/');
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const token = localStorage.getItem('access_token');
        if (!token) {
          showSnackbar('Please log in first.', 'error');
          navigate('/login');
          return;
        }

        const decodedToken = jwtDecode(token);
        const uploaderCID = decodedToken?.cid;
        const authorizedCID = '10811000167';
        if (uploaderCID !== authorizedCID) {
          showSnackbar('Unauthorized: You are not allowed to upload files.', 'error');
          return;
        }

        const response = await fetch('https://trainingportal.desuung.org.bt/api/upload-excel', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Upload successful:', data);

          setUploadedFile(file);
          setRows(data.insertedRows);
          setDuplicateCIDs(data.duplicateCIDs);

          showSnackbar(`File processed successfully! Inserted: ${data.insertedCount}, Duplicates: ${data.duplicateCount}`, 'success');
        } else {
          throw new Error('File upload failed.');
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        showSnackbar('Failed to upload the file. Please try again.', 'error');
      }
    } else {
      showSnackbar('No file selected. Please upload an Excel file.', 'warning');
    }
  };

  const handleEdit = (rowData) => {
    navigate('/dashboard', { state: { rowData } });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const paginatedRows = rows.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: '#E1815B',
          }}
        >
          <Toolbar>
            <Typography variant="h6" noWrap component="div">
              Upload File
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button color="inherit" onClick={handleLogout} startIcon={<Logout />}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <Toolbar />

        <Box sx={{ mt: 2 }}>
          <Typography sx={{ paddingBottom: '10px' }} variant="h5">
            Desuups Details Upload
          </Typography>

          <Button
            variant="contained"
            component="label"
            sx={{
              mt: 2,
              backgroundColor: '#E1815B',
              color: 'white',
              '&:hover': {
                backgroundColor: 'white',
                color: '#E1815B',
                borderColor: '#E1815B',
              },
            }}
          >
            Upload File
            <input
              type="file"
              accept=".xlsx, .xls"
              hidden
              onChange={handleFileUpload}
            />
          </Button>

          {uploadedFile && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">
                Uploaded File: {uploadedFile.name}
              </Typography>
            </Box>
          )}

          {duplicateCIDs.length > 0 && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" color="error">
                Duplicate CIDs:
              </Typography>
              <Box
                sx={{
                  mt: 1,
                  p: 2,
                  border: '1px solid #E1815B',
                  borderRadius: '8px',
                  backgroundColor: '#ffe5d9',
                  maxHeight: '150px',
                  overflowY: 'auto',
                }}
              >
                {duplicateCIDs.map((cid, index) => (
                  <Typography key={index}>{cid}</Typography>
                ))}
              </Box>
            </Box>
          )}

          {Array.isArray(rows) && rows.length > 0 && (
            <>
              <TableContainer component={Paper} sx={{ marginTop: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {Object.keys(rows[0]).map((col) => (
                        <TableCell key={col}>{col}</TableCell>
                      ))}
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedRows.map((row, index) => (
                      <TableRow key={index}>
                        {Object.values(row).map((value, i) => (
                          <TableCell key={i}>{value}</TableCell>
                        ))}
                        <TableCell>
                          <Button variant="outlined" onClick={() => handleEdit(row)}>
                            Edit
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                count={Math.ceil(rows.length / rowsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                sx={{ mt: 2 }}
              />
            </>
          )}
        </Box>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={5000}
          onClose={closeSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={closeSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default Upload;