import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
  const [cid, setCid] = useState('');
  const [password, setPassword] = useState('');
  const [cidError, setCidError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [apiError, setApiError] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    // Reset errors
    setCidError('');
    setPasswordError('');
    setApiError('');

    let isValid = true;

    // CID validation (must be exactly 11 digits)
    const cidRegex = /^\d{11}$/;
    if (!cid) {
        setCidError('CID is required');
        isValid = false;
    } else if (!cidRegex.test(cid)) {
        setCidError('CID must be exactly 11 digits');
        isValid = false;
    }

    // Password validation
    if (!password) {
        setPasswordError('Password is required');
        isValid = false;
    } else if (password.length < 6) {
        setPasswordError('Password must be at least 6 characters');
        isValid = false;
    }

    // If both CID and password are valid, call the API
    if (isValid) {
        try {
            const response = await axios.post('https://trainingportal.desuung.org.bt/api/login/', {
                cid,
                password,
            });

            // Log response status and data
            console.log('API Response:', response.status, response.data);

            // Storing the token in localStorage
            const { token } = response.data;  // Access the token directly
            localStorage.setItem('access_token', token); // Store the token under access_token
            // You can remove refresh_token storage if it's not provided by your API.

            // Set logged in state to true
            setIsLoggedIn(true);
        } catch (error) {
            // Handle API errors
            console.error('API Error:', error);
            setApiError('Login failed. Please check your credentials.');
        }
    }
};


  // Handle navigation after successful login
  useEffect(() => {
    if (isLoggedIn) {
      console.log('Successfully logged in, navigating to dashboard...');
      navigate('/upload'); // Navigate to dashboard after successful login
    }
  }, [isLoggedIn, navigate]);

  return (
    <Container
      maxWidth="xs"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <img
        src="/desu-2.png"
        alt="Desuung Logo"
        style={{ width: '150px', height: '150px' }}
      />
      <Typography variant="h5" gutterBottom>
        De-Suung
      </Typography>
      <Typography variant="h5" gutterBottom>
        Training Portal System
      </Typography>
      <Box
        component="form"
        onSubmit={handleLogin}
        noValidate
        sx={{
          mt: 1,
          width: '100%',
        }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="cid"
          label="CID"
          name="cid"
          autoComplete="cid"
          autoFocus
          value={cid}
          onChange={(e) => setCid(e.target.value)}
          error={Boolean(cidError)}
          helperText={cidError}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#ff9966',
              },
              '&:hover fieldset': {
                borderColor: '#ff7f50',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#ff9966',
              },
            },
          }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={Boolean(passwordError)}
          helperText={passwordError}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#ff9966',
              },
              '&:hover fieldset': {
                borderColor: '#ff7f50',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#ff9966',
              },
            },
          }}
        />
        {apiError && (
          <Typography color="error" sx={{ mt: 2 }}>
            {apiError}
          </Typography>
        )}
        <Button
          type="submit"
          fullWidth
          variant="outlined"
          sx={{
            mt: 3,
            mb: 2,
            backgroundColor: '#E1815B',
            color: 'white',
            borderColor: '#E1815B',
            '&:hover': {
              backgroundColor: 'white',
              color: '#E1815B',
              borderColor: '#E1815B',
            },
          }}
        >
          Login
        </Button>
      </Box>
    </Container>
  );
};

export default Login;
