import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListAltIcon from '@mui/icons-material/ListAlt';
//import Logout from '@mui/icons-material/Logout'; // Import the Logout icon
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const Sidebar = () => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar>
      </Toolbar>
      <Box sx={{ overflow: 'auto' }}>
        <List>
          <ListItem button component={Link} to="/upload">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Upload" />
          </ListItem>
          <ListItem button component={Link} to="/report">
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Report" />
          </ListItem>
          <ListItem 
            button 
            component={Link} to="/" // Adjust the route to your logout handler if needed
            sx={{ mt: 45 }}  // Adding margin-top of 45 (spacing unit)
          >
            {/* <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary="Logout" /> */}
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
