import React, { useState } from 'react'; // Import useState
import { AppBar, Toolbar, Typography, Box, Button, TextField } from '@mui/material';
import Sidebar from './Sidebar';
import { Logout } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Report = () => {
  const navigate = useNavigate();
  const [batch_no, setBatch_no] = useState(''); // State for batch number

  const handleLogout = () => {
    localStorage.removeItem('access_token'); 
    sessionStorage.removeItem('access_token_citizen'); // Remove citizen token on logout
    navigate('/'); 
  };

  const handleBatchNoChange = (event) => {
    setBatch_no(event.target.value); // Update the batch number state
  };

  const handleGenerateReport = () => {
    if (!batch_no) {
      alert('Please enter a batch number'); // Alert if batch number is empty
      return;
    }

    // Call the API to generate the report
    const url = `https://trainingportal.desuung.org.bt/api/details/${batch_no}`; // Update with your server URL

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.blob(); // Return the response as a blob
        }
        throw new Error('Network response was not ok.');
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob])); // Create a URL for the blob
        const a = document.createElement('a'); // Create an anchor element
        a.href = url; // Set the URL as the anchor's href
        a.download = 'desuups_details_report.xlsx'; // Set the desired file name
        document.body.appendChild(a); // Append the anchor to the body
        a.click(); // Trigger a click on the anchor to start the download
        a.remove(); // Remove the anchor from the document
      })
      .catch((error) => {
        console.error('Error fetching the report:', error);
      });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#E1815B' }}>
          <Toolbar>
            <Typography variant="h6" noWrap component="div">
              Dashboard
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button color="inherit" onClick={handleLogout} startIcon={<Logout />}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <Toolbar />

        <Box sx={{ mt: 2 }}>
          <Typography sx={{ paddingBottom: '10px' }} variant="h5">Desuups Details Report</Typography>
          
          {/* Input field for batch number */}
          <TextField
            label="Batch No"
            variant="outlined"
            value={batch_no}
            onChange={handleBatchNoChange}
            sx={{ marginBottom: 2, marginTop: 2 }}
          />
          
          {/* Generate report button */}
          <Button
            variant="outlined"
            onClick={handleGenerateReport} // Trigger report generation on click
            sx={{
              mt: 10,
              mb: 2,
              ml: -28.5,
              width: 229,
              backgroundColor: '#E1815B',
              color: 'white',
              borderColor: '#E1815B',
              '&:hover': {
                backgroundColor: 'white',
                color: '#E1815B',
                borderColor: '#E1815B',
              },
            }}
          >
            Generate
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Report;