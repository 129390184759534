import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Button, TextField, Snackbar, Alert, Grid, FormControl, InputLabel, Select, MenuItem, } from '@mui/material';
import Sidebar from './Sidebar';
import { Logout } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const rowData = location.state?.rowData || {}; // retrieve the rowData from state
  const [CId, setCId] = useState('');

    // Get the CID from localStorage (access token)
    useEffect(() => {
      const token = localStorage.getItem('access_token');
      if (token) {
        // Decode the token to get the CID (assuming JWT is used)
        const decodedToken = jwtDecode(token);
        setCId(decodedToken.cid);  // Assuming the JWT has the `cid` field
        // console.log(setCId());
      }
    }, []);

  // State to hold the form data
  //useState(rowData['full_name'] || '');
  const [formData, setFormData] = useState({
    cid: '',
    dob: '',
    fatherName: '',
    fatherCIDNo: '',
    firstName: '',
    gender: '',
    householdNo: '',
    lastName: '',
    middleName: '',
    mobileNumber: '',
    motherCIDNo: '',
    motherName: '',
    occupation: '',
    dzongkhagName: '',
    gewogName: '',
    villageName: '',
    qualification: '',
    batchNo: '',
    old_cid: '',
    old_dt_id: rowData['dt_id'] || '',
    old_dob: rowData['dob'] || '',
    //old_fatherName: '',
    //old_fatherCIDNo: '',
    old_fullName: rowData['full_name'] || '',
    old_gender: rowData['gender'] || '',
    //old_householdNo: '',
    //old_lastName: '',
    //old_middleName: '',
    old_mobileNumber: rowData['phone_number'] || '',
    old_maritalStatus: rowData['marital_status'] || '',
    old_email: rowData['email'] || '',
    old_bloodGroup: rowData['blood_group'] || '',
    old_Age: rowData['age'] || '',
    old_present_address: rowData['present_address'] || '',
    old_qualification: rowData['qualification'] || '',
    //old_motherCIDNo: '',
    //old_motherName: '',
    old_DegreeCourseStream: rowData['degree_course_stream'] || '',
    old_currentEmploymentStatus: rowData['current_employment_status'] || '',
    old_designation: rowData['designation'] || '',
    old_employmentDetails: rowData['employment_details'] || '',
    old_medicalRecords: rowData['pre_existing_medical_conditions'] || '',
    old_medicalConditionDetails: rowData['medical_condition_description'] || '',
    // old_dzongkhagName: ,
    // old_gewogName: '',
    // old_villageName: '',
    old_primaryContactName: rowData['primary_contact_name'] || '',
    old_primaryContactPhoneNo: rowData['primary_contact_phone'] || '',
    old_relationship: rowData['relationship'] || '',
    old_securityClearance: rowData['security_clearance'] || '',
    old_selectionStatus: rowData['selection_status'] || '',
    old_trainingCenter: rowData['training_center'] || '',
    old_Company: rowData['company'] || '',
    old_DID: rowData['did'] || '',
    old_academicCriteria: rowData['academic_criteria'] || '',
    old_academicStatus: rowData['academic_status'] || '',
    old_Status: rowData['current_status'] || '',
    old_travelDate: rowData['travel_date'] || '',
    old_travelTime: rowData['travel_time'] || '',
    old_pickupInstruction: rowData['pickup_instruction'] || ''
  });

  
  // State to hold the citizen details token
  const [citizenToken, setCitizenToken] = useState('');

  // State to hold error messages
  const [error, setError] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
    // Auto-fill fields up to "Old Mother's CID No" when rowData is available
    useEffect(() => {
      const currentRowData = location.state?.rowData || {}; // Move inside useEffect
    
      if (Object.keys(currentRowData).length > 0) {
        setFormData((prev) => ({
          ...prev,
          old_dt_id: currentRowData.old_dt_id,
          old_cid: currentRowData.old_cid,
          old_dob: currentRowData.old_dob,
          old_fullName: currentRowData.old_fullName,
          old_gender: currentRowData.old_gender,
          old_mobileNumber: currentRowData.old_mobileNumber,
          old_maritalStatus: currentRowData.old_maritalStatus,
          old_email: currentRowData.old_email,
          old_bloodGroup: currentRowData.old_bloodGroup,
          old_Age: currentRowData.old_Age,
          old_present_address: currentRowData.old_present_address,
          old_qualification: currentRowData.old_qualification,
          old_currentEmploymentStatus: currentRowData.old_currentEmploymentStatus,
          old_employmentDetails: currentRowData.old_employmentDetails,
          old_designation: currentRowData.old_designation,
          old_medicalRecords: currentRowData.old_medicalRecords,
          old_medicalConditionDetails: currentRowData.old_medicalConditionDetails,
          // old_dzongkhagName: currentRowData.old_dzongkhagName,
          // old_gewogName: currentRowData.old_gewogName,
          // old_villageName: currentRowData.old_villageName,
          old_primaryContactName: currentRowData.old_primaryContactName,
          old_primaryContactPhoneNo: currentRowData.old_primaryContactPhoneNo,
          old_relationship: currentRowData.old_relationship,
          old_securityClearance: currentRowData.old_securityClearance,
          old_selectionStatus: currentRowData.old_selectionStatus,
          old_trainingCenter: currentRowData.old_trainingCenter,
          old_Company: currentRowData.old_Company,
          old_DID: currentRowData.old_DID,
          old_academicCriteria: currentRowData.old_academicCriteria,
          old_academicStatus: currentRowData.old_academicStatus,
          old_Status: currentRowData.old_Status,
          old_travelDate: currentRowData.old_travelDate,
          old_travelTime: currentRowData.old_travelTime,
          old_pickupInstruction: currentRowData.old_pickupInstruction,
        }));
      }
    }, [location.state]); // Update dependency array
    //console.log("Received row data:", rowData);


  // Check if access_token exists in localStorage when the component mounts
  useEffect(() => {
    
    // Remove citizen token from session storage on refresh
    sessionStorage.removeItem('access_token_citizen');

    const token = localStorage.getItem('access_token');
    if (!token) {
      navigate('/');
    } else {
      const { exp } = jwtDecode(token);
      const isExpired = Date.now() >= exp * 1000;
      if (isExpired) {
        localStorage.removeItem('access_token');
        navigate('/');
      }
    }

    // Check for citizen details token in sessionStorage
    const citizenToken = sessionStorage.getItem('access_token_citizen');
    if (!citizenToken) {
      fetchAccessToken(); // Fetch the access token if it doesn't exist
    } else {
      const { exp } = jwtDecode(citizenToken);
      const isExpired = Date.now() >= exp * 1000;
      if (isExpired) {
        sessionStorage.removeItem('access_token_citizen'); // Remove expired token
        fetchAccessToken();
      } else {
        setCitizenToken(citizenToken); // Set the token state if it's valid
      }
    }
  }, [navigate]);

  // Function to fetch the citizen details access token
  const fetchAccessToken = async () => {
    const response = await fetch('/oauth2/token', {
      method: 'POST',
      headers: {
        'Authorization': 'Basic ' + btoa('RbVnLNu_0BQix6u_LJe4ZQT8wCEa:YjTm5vXVrkI07aLtwxWapbACJUEa'),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: 'grant_type=client_credentials'
    });

    const data = await response.json();
    sessionStorage.setItem('access_token_citizen', data.access_token); // Store in session storage
    setCitizenToken(data.access_token); // Update state
  };

  // Handle logout by removing the token and redirecting to the login page
  const handleLogout = () => {
    localStorage.removeItem('access_token'); 
    sessionStorage.removeItem('access_token_citizen'); // Remove citizen token on logout
    navigate('/'); 
  };

  // Handle form change
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value, // Update the modified field
    }));
  };

  const [oldCid, setOldCid] = useState(rowData['cid'] || '');
  const handleOldCidChange = (e) => setOldCid(e.target.value);
  

  // Fetch citizen details when CID is entered
  const fetchCitizenDetails = async (cid) => {
    if (!citizenToken) return;

    try {
      const response = await fetch(`https://datahub-apim.tech.gov.bt/dcrc_citizen_details_api/1.0.0/citizendetails/${cid}`, {
        headers: {
          'Authorization': `Bearer ${citizenToken}`
        }
      });

      const data = await response.json();

      // Check if citizenDetailsResponse is empty
      if (!data.citizenDetailsResponse || !data.citizenDetailsResponse.citizenDetail || data.citizenDetailsResponse.citizenDetail.length === 0) {
        setError('Invalid CID or no details found for the provided CID');
        //alert('Invalid CID or no details found for the provided CID');
        return;
      }

      // Clear error message if valid response is received
      setError('');

      const citizenDetail = data.citizenDetailsResponse.citizenDetail[0];
      setFormData({
        cid: citizenDetail.cid,
        dob: citizenDetail.dob,
        fatherName: citizenDetail.fatherName,
        fatherCIDNo: citizenDetail.fatherCIDNo,
        firstName: citizenDetail.firstName,
        gender: citizenDetail.gender,
        householdNo: citizenDetail.householdNo,
        lastName: citizenDetail.lastName,
        middleName: citizenDetail.middleName || '',
        mobileNumber: citizenDetail.mobileNumber,
        motherCIDNo: citizenDetail.motherCIDNo,
        motherName: citizenDetail.motherName,
        occupation: citizenDetail.occupation,
        dzongkhagName: citizenDetail.dzongkhagName,
        gewogName: citizenDetail.gewogName,
        villageName: citizenDetail.villageName,
        qualification: citizenDetail.qualification,
        batchNo: formData.batchNo,

      });
    } catch (error) {
      setError('Failed to fetch citizen details. Please try again.');
      // alert('Failed to fetch citizen details. Please try again.');
    }
  };

  // Handle CID input change to fetch details
  const handleCidChange = (e) => {
    const cidValue = e.target.value;
    setFormData({ ...formData, cid: cidValue });
    if (cidValue.length === 11) {
      fetchCitizenDetails(cidValue); // Fetch details when CID is entered
    }
  };

  // Function to convert date format from DD/MM/YYYY to YYYY-MM-DD
  const formatDateToYYYYMMDD = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  };

  //console.log('formdata:', formData);


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Convert the date format for the 'dob' field
    const formattedDob = formatDateToYYYYMMDD(formData.dob);
  
    // Prepare data to send to the API
    const dataToSubmit = {
      ...formData, // Include all form fields (whether changed or not)
      dob: formattedDob, // Use the formatted date here
    };
  
    // Manually add old values for fields that don't have a direct form input
    dataToSubmit.old_dt_id = formData['old_dt_id'] || rowData['dt_id'] || '';
    dataToSubmit.old_cid = rowData['cid'] || ''; // Use rowData value, or empty string if null
    dataToSubmit.old_fullName = formData["Full Name"] || rowData['full_name'] || ''; // Fallback if null
    dataToSubmit.old_dob = formData["old_dob"] || rowData['dob'] || ''; // Fallback if null
    dataToSubmit.old_gender = formData["old_gender"] || rowData['gender'] || '';
    dataToSubmit.old_mobileNumber = formData["old_mobileNumber"] || rowData['phone_number'] || '';
    dataToSubmit.old_maritalStatus = formData["old_maritalStatus"] || rowData['marital_status'] || '';
    dataToSubmit.old_email = formData["old_email"] || rowData['email'] || '';
    dataToSubmit.old_bloodGroup = formData["old_bloodGroup"] || rowData['blood_group'] || '';
    dataToSubmit.old_Age = formData["old_Age"] || rowData['age'] || '';
    dataToSubmit.old_present_address = formData["old_present_address"] || rowData['present_address'] || '';
    dataToSubmit.old_qualification = formData["old_qualification"] || rowData['qualification'] || '';
    dataToSubmit.old_DegreeCourseStream = formData["old_DegreeCourseStream"] || rowData['degree_course_stream'] || '';
    dataToSubmit.old_currentEmploymentStatus = formData["old_currentEmploymentStatus"] || rowData['current_employment_status'] || '';
    dataToSubmit.old_designation = formData["old_designation"] || rowData['designation'] || '';
    dataToSubmit.old_employmentDetails = formData["old_employmentDetails"] || rowData['employment_details'] || '';
    dataToSubmit.old_medicalRecords = formData["old_medicalRecords"] || rowData['pre_existing_medical_conditions'] || '';
    dataToSubmit.old_medicalConditionDetails = formData["old_medicalConditionDetails"] || rowData['medical_condition_description'] || '';
    dataToSubmit.old_primaryContactName = formData["old_primaryContactName"] || rowData['primary_contact_name'] || '';
    dataToSubmit.old_primaryContactPhoneNo = formData['old_primaryContactPhoneNo'] || rowData['primary_contact_phone'] || '';
    dataToSubmit.old_relationship = formData['old_relationship'] || rowData['relationship'] || '';
    dataToSubmit.old_securityClearance = formData['old_securityClearance'] || rowData['security_clearance'] || '';
    dataToSubmit.old_selectionStatus = formData['old_selectionStatus'] || rowData['selection_status'] || '';
    dataToSubmit.old_trainingCenter = formData['old_trainingCenter'] || rowData['training_center'] || '';
    dataToSubmit.old_Company = formData['old_Company'] || rowData['company'] || '';
    dataToSubmit.old_DID = formData['old_DID'] || rowData['did'] || '';
    dataToSubmit.old_academicCriteria = formData['old_academicCriteria'] || rowData['academic_criteria'] || '';
    dataToSubmit.old_academicStatus = formData['old_academicStatus'] || rowData['academic_status'] || '';
    dataToSubmit.old_Status = formData['old_Status'] || rowData['current_status'] || '';
    dataToSubmit.old_travelDate = formData['old_travelDate'] || rowData['travel_date'] || '';
    dataToSubmit.old_travelTime = formData['old_travelTime'] || rowData['travel_time'] || '';
    dataToSubmit.old_pickupInstruction = formData['old_pickupInstruction'] || rowData['pickup_instruction'] || '';
  
    dataToSubmit.created_by = CId; // Include the user ID or any necessary identifier
    
    console.log('dataToSubmit', dataToSubmit);
    try {
      const response = await fetch('https://trainingportal.desuung.org.bt/api/details', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSubmit),
      });
  
      if (response.ok) {
        console.log('Form submitted successfully!');
        setSnackbarOpen(true); // Notify user about success
        // Optionally reset the form or navigate to another page
      } else {
        const errorDetails = await response.json(); // Try extracting error details
        console.error('Failed to submit the form:', errorDetails.message || response.statusText);
        alert(`Error: ${errorDetails.message || 'CID already exists!!.'}`);
      }
    } catch (error) {
      console.error('Error while submitting the form:', error);
      alert('An unexpected error occurred. Please try again later.');
    }
  };
  
  //console.log('formdata:',formData);
  
  

  // Handle Snackbar close
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#E1815B' }}>
          <Toolbar>
            <Typography variant="h6" noWrap component="div">
              Dashboard
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button color="inherit" onClick={handleLogout} startIcon={<Logout />}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <Typography sx={{paddingBottom: '19px'}} variant="h5">Desuups Details Verification</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <form onSubmit={handleSubmit}>
            <Grid container spacing={2} sx={{ flex: 1 }}>
            <Grid item xs={6}>
            <TextField
                  required
                  fullWidth
                  label="DT ID"
                  name="old_dt_id"
                  InputLabelProps={{ shrink: true }}
                  //value={rowData['cid'] || ''}
                  value={formData.old_dt_id}
                  //onChange={handleOldCidChange} // Change the handler to handle CID input
                  disabled
                  sx={{ mb: 2 }}
                />
              <TextField
                  required
                  fullWidth
                  label="Old CID"
                  name="oldCid"
                  //value={rowData['cid'] || ''}
                  value={oldCid}
                  onChange={handleOldCidChange} // Change the handler to handle CID input
                  disabled
                  sx={{ mb: 2 }}
                />

                {/* {error && (
                  <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                    {error}
                  </Typography>
                )} */}

                <TextField
                  required
                  fullWidth
                  label="Old Full Name"
                  name="Full Name"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_fullName}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  required
                  fullWidth
                  label="Old Date of Birth"
                  name="old_dob"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  value={formData.old_dob}
                  onChange={handleChange}
                  placeholder="DD-MM-YYYY" // Placeholder for date format
                  
                  sx={{ mb: 2 }}
                />

                <FormControl fullWidth sx={{ mb: 2 }}>
                  {/* Conditionally render the InputLabel only when gender is null */}
                  {rowData.gender === null || rowData.gender === '' ? (
                    <InputLabel id="old_gender-label">Old Gender</InputLabel>
                  ) : null}

                  {/* Check if gender is null or empty */}
                  {rowData.gender === null || rowData.gender === '' ? (
                    // If the gender is null or empty, show the dropdown
                    <Select
                      labelId="old_gender-label"
                      label="Old Gender"
                      name="old_gender"
                      value={formData.old_gender || ''} // Ensure it's an empty string if null
                      onChange={handleChange}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  ) : (
                    // If there is a value, show the pre-filled value in a read-only TextField
                    <TextField
                      fullWidth
                      name="old_gender"
                      value={formData.old_gender}
                      InputLabelProps={{ shrink: false }} // Ensure the label does not appear
                      onChange={handleChange}
                      sx={{ mb: 2 }}
                      InputProps={{
                        readOnly: false, // Make the input read-only if pre-filled
                      }}
                    />
                  )}
                </FormControl>


                <TextField
                  fullWidth
                  label="Old Phone Number"
                  name="old_mobileNumber"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_mobileNumber}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Old Marital Status"
                  name="old_maritalStatus"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_maritalStatus}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Old Email"
                  name="old_email"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_email}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Old Blood Group"
                  name="old_bloodGroup"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_bloodGroup}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Old Age"
                  name="old_Age"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_Age}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Present Address"
                  name="old_present_address"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_present_address}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Old Qualification"
                  name="old_qualification"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_qualification}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Old Degree/Course/Stream"
                  name="old_DegreeCourseStream"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_DegreeCourseStream}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Old Current Employment Status"
                  name="old_currentEmploymentStatus"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_currentEmploymentStatus}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Old Designation"
                  name="old_designation"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_designation}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Old Employment Details"
                  name="old_employmentDetails"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_employmentDetails}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Old Do you have any pre-existing medical conditions?"
                  name="old_medicalRecords"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_medicalRecords}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Old Describe Your Medical Condition"
                  name="old_medicalConditionDetails"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_medicalConditionDetails}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Old Primary Contact Name"
                  name="old_primaryContactName"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_primaryContactName}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Old Primary Contact Phone No"
                  name="old_primaryContactPhoneNo"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_primaryContactPhoneNo}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Old Relationship"
                  name="old_relationship"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_relationship}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Old Travel Date"
                  name="old_travelDate"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_travelDate}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />

            </Grid>
            <Grid item xs={6}>  
              <TextField
                required
                fullWidth
                label="CID"
                name="cid"
                value={formData.cid}
                onChange={handleCidChange} // Change the handler to handle CID input
                sx={{ mb: 2 }}
              />

              {error && (
                <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                  {error}
                </Typography>
              )}

              <TextField
                required
                fullWidth
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                disabled 
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Middle Name"
                name="middleName"
                value={formData.middleName}
                onChange={handleChange}
                disabled 
                sx={{ mb: 2 }}
              />
              <TextField
                required
                fullWidth
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                disabled 
                sx={{ mb: 2 }}
              />
              <TextField
                required
                fullWidth
                label="Date of Birth"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                placeholder="DD/MM/YYYY" // Placeholder for date format
                disabled 
                sx={{ mb: 2 }}
              />
              
              <TextField
                fullWidth
                label="Gender"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                disabled 
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Mobile Number"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                disabled 
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Dzongkhag Name"
                name="dzongkhagName"
                value={formData.dzongkhagName}
                onChange={handleChange}
                disabled 
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Gewog Name"
                name="gewogName"
                value={formData.gewogName}
                onChange={handleChange}
                disabled 
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Village Name"
                name="villageName"
                value={formData.villageName}
                onChange={handleChange}
                disabled 
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Qualification "
                name="qualification"
                value={formData.qualification}
                onChange={handleChange}
                disabled 
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Batch No"
                name="batchNo"
                required
                value={formData.batchNo}
                onChange={handleChange}
                sx={{ mb: 2 }}
              />
              <TextField
                  fullWidth
                  label="Old Security Clearance"
                  name="old_securityClearance"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_securityClearance}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <FormControl fullWidth sx={{ mb: 2 }}>
                  {/* Conditionally render the InputLabel only when old_selectionStatus is null or empty */}
                  {rowData.selection_status === null || rowData.selection_status === '' ? (
                    <>
                      <InputLabel id="old_selection_status">Old Selection Status</InputLabel>
                      <Select
                        labelId="old_selection_status"
                        label="Old Selection Status"
                        name="old_selectionStatus"
                        value={formData.old_selectionStatus || ''} // Ensure it's an empty string if null
                        onChange={handleChange}
                      >
                        <MenuItem value="Selected">Selected</MenuItem>
                        <MenuItem value="Not Selected">Not Selected</MenuItem>
                        <MenuItem value="Rejected">Shortlisted</MenuItem>
                      </Select>
                    </>
                  ) : (
                    // If there is a value, show the pre-filled value in a read-only TextField
                    <TextField
                      fullWidth
                      name="old_selectionStatus"
                      value={rowData.selection_status || ''} // Default to empty string if undefined
                      InputLabelProps={{ shrink: true }}
                      sx={{ mb: 2 }}
                      InputProps={{
                        readOnly: false, // Make the input read-only
                      }}
                    />
                  )}
                </FormControl>

                {/* <TextField
                  fullWidth
                  label="Old Training Centre"
                  name="old_trainingCenter"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_trainingCenter}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                /> */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                  {/* Conditionally render the InputLabel only when training_center is null or empty */}
                  {rowData.training_center === null || rowData.training_center === '' ? (
                    <>
                      <InputLabel id="old_training_center">Old Training Center</InputLabel>
                      <Select
                        labelId="old_training_center"
                        label="Old Training Centre"
                        name="old_trainingCenter"
                        value={formData.old_trainingCenter || ''} // Ensure it's an empty string if null
                        onChange={handleChange}
                      >
                        <MenuItem value="Wing I, Tendruk">Wing I, Tendruk</MenuItem>
                        <MenuItem value="Wing V, Tashichholing">Wing V, Tashichholing</MenuItem>
                        <MenuItem value="Wing X, Dewathang">Wing X, Dewathang</MenuItem>
                        <MenuItem value="Wing IX, Pelrithang">Wing IX, Pelrithang</MenuItem>
                        <MenuItem value="Commando Wing, Shaba">Commando Wing, Shaba</MenuItem>
                        <MenuItem value="SRPF, Tashigatshel">SRPF, Tashigatshel</MenuItem>
                        <MenuItem value="RBPTI, Jigmeling">RBPTI, Jigmeling</MenuItem>
                        <MenuItem value="MTC, Tencholing">MTC, Tencholing</MenuItem>
                      </Select>
                    </>
                  ) : (
                    // If there is a value, show the pre-filled value in a read-only TextField
                    <TextField
                      fullWidth
                      name="old_trainingCenter"
                      value={rowData.training_center || ''} // Default to empty string if undefined
                      InputLabelProps={{ shrink: true }}
                      sx={{ mb: 2 }}
                      InputProps={{
                        readOnly: false, // Make the input read-only
                      }}
                    />
                  )}
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  {/* Conditionally render the InputLabel only when old_Company is null or empty */}
                  {rowData.company === null || rowData.company === '' ? (
                    <>
                      <InputLabel id="old_company">Old Company</InputLabel>
                      <Select
                        labelId="old_company"
                        label="Old Company"
                        name="old_Company"
                        value={formData.old_Company || ''} // Ensure it's an empty string if null
                        onChange={handleChange}
                      >
                        <MenuItem value="TAAG">TAAG</MenuItem>
                        <MenuItem value="SINGYE">SINGYE</MenuItem>
                        <MenuItem value="CHHUNG">CHHUNG</MenuItem>
                        <MenuItem value="DRUK">DRUK</MenuItem>
                      </Select>
                    </>
                  ) : (
                    // If there is a value, show the pre-filled value in a read-only TextField
                    <TextField
                      fullWidth
                      name="old_Company"
                      value={rowData.company || ''} // Default to empty string if undefined
                      InputLabelProps={{ shrink: true }}
                      sx={{ mb: 2 }}
                      InputProps={{
                        readOnly: false, // Make the input read-only
                      }}
                    />
                  )}
                </FormControl>

                <TextField
                  fullWidth
                  label="Old DID"
                  name="old_DID"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_DID}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Old Academic Criteria"
                  name="old_academicCriteria"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_academicCriteria}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Old Academic Status"
                  name="old_academicStatus"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_academicStatus}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <FormControl fullWidth sx={{ mb: 2 }}>
                  {formData.old_selectionStatus === 'Selected' ? (
                    <>
                      <InputLabel id="remarks">Remarks</InputLabel>
                      <Select
                        labelId="remarks"
                        label="Remarks"
                        name="old_Status"
                        value={formData.old_Status || ''} // Ensure it's an empty string if null
                        onChange={handleChange}
                      >
                        <MenuItem value="Selected">Selected</MenuItem>
                        <MenuItem value="RC">RC</MenuItem>
                      </Select>
                    </>
                  ) : formData.old_selectionStatus === 'Not Selected' ? (
                    <>
                      <InputLabel id="remarks">Remarks</InputLabel>
                      <Select
                        labelId="remarks"
                        label="Remarks"
                        name="old_Status"
                        value={formData.old_Status || ''} // Ensure it's an empty string if null
                        onChange={handleChange}
                      >
                        <MenuItem value="Self Withdraw">Self Withdraw</MenuItem>
                        <MenuItem value="No Qualification">No Qualification</MenuItem>
                        <MenuItem value="Medical Issue">Medical Issue</MenuItem>
                        <MenuItem value="Medical Issue">Under Age</MenuItem>
                        <MenuItem value="Medical Issue">Employed</MenuItem>
                        <MenuItem value="Medical Issue">Wrong CID</MenuItem>
                        <MenuItem value="Medical Issue">Both Phone Number Wrong</MenuItem>
                      </Select>
                    </>
                  ) : (
                    // Default state when old_selectionStatus is not Selected or Not Selected
                    <TextField
                      fullWidth
                      label="Remarks"
                      name="old_Status"
                      InputLabelProps={{ shrink: true }}
                      value={formData.old_Status || ''}
                      onChange={handleChange}
                      sx={{ mb: 2 }}
                    />
                  )}
                </FormControl>

                <TextField
                  fullWidth
                  label="Old Pick-up Instructions"
                  name="old_pickupInstruction"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_pickupInstruction}
                  onChange={handleChange}
                  
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Old Travel Time"
                  name="old_travelTime"
                  InputLabelProps={{ shrink: true }}
                  value={formData.old_travelTime}
                  onChange={handleChange}
                  type="time"
                  sx={{ mb: 2 }}
                />
              <Button
                type="submit"
                variant="outlined"
                sx={{
                  mt: 2,
                  mb: 2,
                  ml: 5,
                  width:"600px",
                  backgroundColor: '#E1815B',
                  color: 'white',
                  borderColor: '#E1815B',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: '#E1815B',
                    borderColor: '#E1815B',
                  },
                }}
              >
                Save
              </Button>
              </Grid>
              </Grid>
            </form>
        </Box>
      </Box>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Saved successfully!
        </Alert>
        {/* <Alert onClose={handleSnackbarClose} severity="danger" sx={{ width: '100%' }}>
          CID already Exist!
        </Alert> */}
      </Snackbar>
    </Box>
  );
};

export default Dashboard;